<script setup>
import Content from '@/views/Layouts/Content.vue'
import Contact from '@/components/Contact/Contact.vue'
// import Contact from '@/views/Contacts/Contact.vue'
// import Edit from '@/components/Edit.vue'

import { useVuelidate } from '@vuelidate/core'
import { useStore } from 'vuex'
import { useRouter } from 'vue-router'

import { reactive, ref, onMounted, watch } from 'vue'

const store = useStore()
const router = useRouter()
const v = useVuelidate()

const contact = ref(store.getters['contact/GET_MODEL'])

const create = () => {
  console.log('create')
  console.log(contact.value)
  store.dispatch('contact/createItem', contact.value).then(() => {
    router.push('/contacts')
  })
}
 
</script>
<template>
  <div>
    <!-- <pre> {{ v }}</pre> -->
    <Content>
      <template #header-controls>
        <!-- <div class="flex gap-3 items-center"> -->
        <!-- <span class="sm:ml-3">
          <button class="btn btn-primary">
            <svg class="-ml-1 mr-2 h-5 w-5" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor"
              aria-hidden="true">
              <path fill-rule="evenodd"
                d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                clip-rule="evenodd" />
            </svg>
            Создать
          </button>
        </span> -->
        <!-- <div class="flex-1">
          <input type="text" placeholder="Фильтр" class="w-full" />
        </div>
        <div>
          <button>
            <CogIcon class="h-5 w-5" aria-hidden="true" />
          </button>
        </div> -->
        <!-- </div> -->
      </template>
      <template #title>
        <div class="">
          <h3 class="">Новый контакт</h3>
          <p class="mt-1 max-w-2xl text-sm text-gray-500">Создание нового контакта</p>
        </div>
      </template>
      <template #content>
        <!-- <Contact /> -->
        <div class="mx-auto md:w-1/2">
          <Contact v-model="contact" />
          <div class="mt-5">
            <button class="btn btn-primary" :disabled="v.$silentErrors.length != 0" @click="create">Создать</button>
          </div>
        </div>
        <!-- <div class="my-3">
          <img src="/tmp/contacts-list.png" alt="" srcset="">
        </div> -->

        <!-- <pre>{{  v$  }}</pre> -->



      </template>
    </Content>
  </div>
</template>